<script setup lang="ts">
const { url } = useImageStorage()

defineEmits([
  'click:login',
])
</script>

<template>
  <div class="w-full flex flex-col items-center gap-2.5">
    <AButton
      custom-class="w-full text-base"
      button-block
      button-variant="outline"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/kakao.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.kakao')"
      @click="() => $emit('click:login', 'kakao')"
    />
    <AButton
      custom-class="w-full text-base"
      button-block
      button-variant="outline"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/naver.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.naver')"
      @click="() => $emit('click:login', 'naver')"
    />
    <AButton
      custom-class="w-full text-base"
      button-block
      button-variant="outline"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/google.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.google')"
      @click="() => $emit('click:login', 'google')"
    />
    <AButton
      custom-class="w-full text-base"
      button-block
      button-variant="outline"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/apple.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.apple')"
      @click="() => $emit('click:login', 'apple')"
    />
  </div>
</template>
